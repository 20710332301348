import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          alignCenter
        >
          <Header />
          <CFView textCenter w="100%">
            <CFView mt="30px">
              <CFImage
                mb="20px"
                w="95%"
                src={mobileHeroText}
                alt="Minori Sushi hero text"
                zIndex={98}
              />
            </CFView>
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxWidth="1400px"
          maxHeight="680px"
          w="100%"
          m="0 auto"
          image={`url(${hero}) left/ cover no-repeat`}
          zIndex={90}
          column
          alignStart
        >
          <CFView column justifyCenter alignStart ml="80px">
            <CFView mt="10VH">
              <CFImage
                w="60vw"
                maxWidth="460px"
                src={heroText}
                alt="Minori Sushi hero text"
                zIndex={98}
              />
              <CFView
                style={{ fontSize: 28, lineHeight: '35px' }}
                maxWidth="510px"
                mt="10px"
              >
                Welcome to Minori Sushi! We look forward to serving you!
              </CFView>
            </CFView>
            <CFView mv="30px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
